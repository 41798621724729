import axios from 'axios';

const url = process.env.VUE_APP_URL;

class VideoService{

static async TwitchLogin(token){
    const result = await axios.get(url+'getLogin/'+token)
    if(result.data.status!=401){
    return result.data.data[0].login
    }else{
        return null
    }
}

static async getToken(code){
    const result = await axios.post(url+'getToken',{
    
            code: code 
    
    })
    if(result.data.status!=400){
        return result.data.access_token
    }else return null
}

static async addMovie(movie){

    const result = await axios.post(url+'queue/add',{
        movie: movie
    })
    if(result){
        return 'Added to the queue!'
    }else{
        return 'Something went wrong!'
    }
}

static async getMovies(){

    const result = await axios.get(url+'queue/get')

    return result

    
}

static async clearQueue(){
    await axios.post(url+'queue/clear')
}

}

export default VideoService;