import { defineStore } from 'pinia'

const tokenStore = defineStore('token',  {
  state: () => ({ 
    
    token: JSON.parse(localStorage.getItem('token')) || null
    
  }),

  actions: {
    setToken(token) {
      this.token = token
      localStorage.setItem('token', JSON.stringify(this.token))
    },
  },
  
}
)

export default tokenStore;
